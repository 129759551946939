<template>
  <v-footer id="dashboard-core-footer">
    <v-container>
      <v-row align="center">
        <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center mb-sm-0 mb-5"
          cols="auto"
        >
          <v-btn
            :to="link.href"
            class="mr-0 grey--text text--darken-3"
            rel="noopener"
            target=""
            v-text="link.text"
            text
            small
          ></v-btn>
        </v-col>

        <v-spacer class="hidden-sm-and-down" />
        
        <v-col cols="12" md="auto">
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; 2022 Province of Iloilo, Philippines
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "DashboardCoreFooter",

  data: () => ({
    links: [
      {
        href: "/aboutus",
        text: "About Us",
      },
      {
        href: "/howtousesite",
        text: "How to use Site",
      },
      {
        href: "/contactus",
        text: "Contact Us",
      },
      {
        href: "/termsagreementpolicy",
        text: "Privacy Policy",
      },
    ],
  }),
};
</script>

<style lang="sass">
#dashboard-core-footer
  a
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase
    padding-left:.5em
    padding-right:.5em
</style>
